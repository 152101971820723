// Here you can add other styles


body{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  font-size: 12px;
}

.table-bordered thead th, .table-bordered td  {
  font-size: 12px;
}

.table-bordered thead th {
  font-family: 'Courier New', Courier, monospace;
}

.table-bordered th, .table-bordered td {
  border:1px solid #e4e7ea;
  font-size: 11px;
}

.btn-primary{
  color: #fff;
  background-color: #ff5a1e;
  border-color: #ff5a1e !important;
}

input:checked .slider {
  background-color: #219615;
}

.table th, .table td{
  padding: 5px 10px;


  a {
    color: #23282c;
  }
  a:hover {
    color: #ff5a1e;
  }
}

.table tr:hover td{
  background-color:  #e4e7ea;
}

.rotate-90 {
  transform: rotate(90deg);
}

.text-white:link {
  color: #333333;
}

.dark-color {
  background-color: #FFF;
}

.display-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sidebar {
  background-color: #fff;
}
.sidebar .nav-link {
  color: #333333;
}

.sidebar .nav-dropdown.open .nav-link {
  color: #333333;
}

.sidebar .nav-link.active {
  color: #ff5a1e;
  border-bottom : 2px coral solid;
}

.sidebar .nav-link:hover {
  color: #fff;
  background-color: #ff5a1e;
}
.sidebar .nav-link.active:hover {
  color: #fff;
}

.sidebar .nav-link:hover .nav-icon {
  color: #FFF !important;
}

.sidebar .nav-link.active:hover .nav-icon {
  color: #FFF !important;
}

.sidebar .nav-dropdown.open .nav-link.active {
  color: #fff;
}

.sidebar .nav-dropdown.open .nav-link.active:hover {
  color: #FFF !important;
}

.sidebar .nav-link.active {
  color: #ff5a1e;
  border-bottom : 2px coral solid;
}

.sidebar .nav-dropdown.open {
  background-color: #FFF;

  ul {
    background-color: rgba(0, 0, 0, 0.2);
    .nav-dropdown.open {

    background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.breadcrumb {
  padding: 0.45rem 0.40rem;
  font-size: 10px;
  border-bottom: none;
}

.sidebar .nav-link.active .nav-icon {
  color: #ff5a1e !important;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dcdadb;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #21961A;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

a {
  color: rgb(255, 90, 30);
}

.sidebar .sidebar-minimizer ,
.sidebar-minimized .sidebar .sidebar-minimizer {

  background-color: white;
}

.container-fluid {
  .card.bg-info {
    border-color:  #ff5a1e !important;
  }
  .nav-link {
    padding: 0;
  }
  .bg-info {
    color: white;
  }
  .text-title {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #23282c;
  }
  .bg-info {
    background-color:#ff5a1e!important;
  }
}

.header-fixed .app-body {
  margin-top: 45px;
}
.app-header, .app-header .navbar-brand {
  height: 45px;
}

.app-header .nav-item .nav-link > .img-avatar, .app-header .nav-item .avatar.nav-link > img {
  height: 25px;
}
.user-name {
  margin-bottom: 0;
  color: #73818f;
  font-size: 10px;
}

.portal-env {
  color: #e4e7ea;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  font-family: 'FontAwesome';
  padding-left: 23px;
  margin-bottom: 0;
}

.page-link {
  color: #ff5a1e!important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #ff5a1e;
  border-color: #ff5a1e;
}

.genericEditModal{
  width:100%;
}

.genericEditModalPadding{
  padding: 4%
}

.date-time-margin { margin-top:30px; }

.button-action { margin:5px; width: 30px;}

.center {
  margin-left: auto;
  margin-right: auto;
}

.DateInput_input {
  font-weight: 100 !important;
  font-size: 12px !important;
  line-height: 12px !important;
}


.nav-tabs .nav-link.active{
  background: #dfdfdf !important;

}
